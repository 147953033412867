@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;500&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');


.home__hero-section {
  color: #fff;
  padding: 40px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-family: 'Darker Grotesque', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 700;
  color: #f7f8fa;
  font-family: 'Quicksand', Tahoma, Geneva, Verdana, sans-serif;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  font-family: 'Quicksand', Tahoma, Geneva, Verdana, sans-serif;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}
.badge-apple {
  width: 13rem;
}
.badge-google {
  width: 16rem;
}
.badge-row {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .badge-google {
    margin-left: -1rem;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    max-width: 555px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
  .badge-google {
    margin-left: 0rem;
    width: 55%;
  }
  .badge-apple {
    width: 44%;
  }
  .home__hero-subtitle {
    font-size: 16px;
  }
}
